<template>
  <div>
      <template v-if="showingAddForm">
          <div>
              <slot name="addform"
                    :eventName="getAttemptedEventName()"
                    :firstName="getAttemptedFirstName()"
                    :lastName="getAttemptedLastName()"
              >
              </slot>
          </div>
      </template>
      <template v-else>
          <div :class="titleClasses">
            <div>
              <slot name="title"></slot>
              <div class="search-input">
                <input v-model="search" :placeholder="`\uF002 ${placeholder}`" :class="inputSize()">
                <spinner v-if="searching"></spinner>
              </div>
            </div>
            <slot name="ctas"></slot>
          </div>
          <slot name="headers"></slot>
          <div :class="resultCountClasses">
            <small v-show="searching && search.length > 0">Searching for <span class='text-navy'>{{ search }}</span></small>
            <small v-show="resultsLoaded && search.length > 0">{{ resultCount | resultPluralize }} for <span
                class='text-navy'>{{ search }}</span></small>
            <template v-if="resultsLoaded && (resultCount === 0 || resultCount === '0')">
              <slot name="nothingfound"></slot>
            </template>
            <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
          </div>
          <div class="search-results" v-show="resultsLoaded">
              <slot name="list"
                    v-for="(result, index) in results"
                    :index="index"
                    :result="result">
              </slot>
              <div id="loading-more" class="all-result m-t-xs">
                  <small v-show="loadingMore">Loading more {{ getModelNameForResults() }} matching <span class='text-navy'>{{ search }}</span>...</small>
                  <spinner v-if="loadingMore"></spinner>
                  <small v-show="noMoreData && search !== ''">All {{ getModelNameForResults() }} matching <span class='text-navy'>{{ search }}</span> shown</small>
              </div>
              <div v-if="allowAdd" class="add-record">
                  <small v-show="resultsLoaded && search.length > 0"><a @click="showAddClicked()">Not found? Create a new {{ readableModelName }}</a>
                  </small>
              </div>
          </div>
      </template>
  </div>
</template>

<script>
/*
     * This is what we use for a generic type-as-you-go search
     * With an optional slow for adding new data types
     * This component is also used for 'search select' scenarios like the match tag form
     * It handles hitting an arbitrary (well at least WrestlingIQ)
     * json API endpoint remotely with axios to pull down results.
     *
     * It handles the UI for displaying a spinner, loading results in a debounced fashion
     * and handling triggering a call to the next page of results automatically when the
     * user gets close to the bottom of the view. Also handles errors with a simple message.
     *
     * This also appends a query parameter whenever a search happens so that
     * the user can link to it. Internally this uses history push state.
     *
     *
     * params:
     *   baseUrl - i.e. http://localhost:3000/api/v1/<index_action> (without any parameters)
     *   responseKey - The root json key to pluck the relevant array out of
     *
     *   slot named list - You as the consumer of search must provide a named slot
     *   which dictates the UI. Using this slot with templates allows this component
     *   to remain data agnostic - parsing and displaying the actual json is up to the list
     *   template you pass into the slow. See _scouting for a good example of this.
     */
import Spinner from '../wrestling/vue/spinner.vue';
import { paramAndHistoryMixin } from '../wrestling/vue/mixins/parameter_history_mix';

export default {
  components: {
    Spinner,
  },
  mixins: [
    paramAndHistoryMixin,
  ],
  name: 'search',
  props: [
    'baseUrl',
    'responseKey',
    'placeholder',
    'displayAllOnEmpty',
    'queryKey',
    'readableModelName',
    'allowAdd',
    // If we want to ensure the 'profile-clicked' notification manager is restricted to specific models, pass it in
    'onlyDismissModel',
    'mutateHistory',
    'profilesOnly',
    'guardiansOnly',
    'billables',
    'smallInput',
    'includeGuests',
    // Old
    'rosterId',
    // New
    'rosterIds',
    'queryDeleted',
    'titleClasses',
    'resultCountClasses',
  ],
  data() {
    return {
      showingAddForm: false,

      search: '',
      searching: false,

      loadingMore: false,
      noMoreData: false,

      nextPageUrl: null,
      page: 1,
      perPage: 30,

      resultsLoaded: false,
      resultCount: 0,
      results: [],

      error: false,
      errorMessage: '',
    };
  },
  filters: {
    resultPluralize(resultCount) {
      if (resultCount === '1') {
        return `${resultCount} result`;
      }

      return `${resultCount} results`;
    },
  },
  created() {
    const vm = this;

    // _.debounce is a function provided by lodash to limit how
    // often a particularly expensive operation can be run.
    // In this case, we want to limit how often we access
    // api, waiting until the user has completely
    // finished typing before making the ajax request. To learn
    // more about the _.debounce function (and its cousin
    // _.throttle), visit: https://lodash.com/docs#debounce

    // Normally this is hung off of the function definition itself;
    // however having two components on the same page appears to screw it up.
    // hooking it up inside the create appears to isolate it to the unique component
    this.getSearchResults = _.debounce(this.getSearchResults, 500);
    window.addEventListener('scroll', _.throttle(vm.handleScroll, 500));

    vm.search = vm.getInitialQuery();
    if (vm.search.length === 0 && vm.displayAllOnEmpty === true) {
      this.getSearchResults();
    }

    vm.$notificationManager.$on('cancel-opposing-wrestler-create', vm.hideAddForm);
    vm.$notificationManager.$on('cancel-opposing-team-create', vm.hideAddForm);
    vm.$notificationManager.$on('cancel-event-create', vm.hideAddForm);
    vm.$notificationManager.$on('cancel-customer-create', vm.hideAddForm);

    vm.$notificationManager.$on('profile-clicked', vm.emptyAndHide);
    vm.$notificationManager.$on('event-invite-clicked', vm.emptyAndHide);
    vm.$notificationManager.$on('opposing-wrestler-created', vm.emptyAndHide);
    vm.$notificationManager.$on('event-created', vm.emptyAndHide);
    vm.$notificationManager.$on('single-roster-filter-requested', vm.emptyAndHide);


    // This should only fire in certain scenarios
    vm.$notificationManager.$on('opposing-team-created', vm.opponentCreated);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    const vm = this;
    vm.$notificationManager.$off('cancel-event-create', vm.hideAddForm);
    vm.$notificationManager.$off('cancel-opposing-team-create', vm.hideAddForm);
    vm.$notificationManager.$off('cancel-opposing-wrestler-create', vm.hideAddForm);
    vm.$notificationManager.$off('cancel-customer-create', vm.hideAddForm);

    vm.$notificationManager.$off('profile-clicked', vm.emptyAndHide);
    vm.$notificationManager.$off('event-invite-clicked', vm.emptyAndHide);
    vm.$notificationManager.$off('opposing-wrestler-created', vm.emptyAndHide);
    vm.$notificationManager.$off('single-roster-filter-requested', vm.emptyAndHide);
    vm.$notificationManager.$off('event-created', vm.emptyAndHide);
    vm.$notificationManager.$off('opposing-team-created', vm.opponentCreated);
  },
  computed: {
    restrictedDismiss() {
      return this.onlyDismissModel && this.onlyDismissModel.length > 0;
    }
  },
  watch: {
    // whenever search changes, this function will run
    search(newSearch) {
      this.getSearchResults();
    },
    searching(newSearching) {
      this.$notificationManager.$emit('searching', newSearching);
    },
    rosterId(newRosterId) {
      this.getSearchResults();
    },
    rostersId(newRostersId) {
      this.getSearchResults();
    }
  },
  methods: {
    inputSize() {
      if (this.smallInput) {
        return 'form-control';
      }

      return 'form-control input-lg';
    },
    opponentCreated(opposingTeam) {
      const vm = this;
      if (vm.readableModelName === 'opposing team') {
        vm.emptySearchQuery();
        vm.hideAddForm();
      }
    },
    emptyAndHide(restrictedToModel) {
      const vm = this;

      // Make sure we bail if we aren't supposed to respect the empty/hide
      if (this.restrictedDismiss && this.onlyDismissModel !== restrictedToModel) {
        return;
      }

      vm.emptySearchQuery();
      vm.hideAddForm();
    },
    emptySearchQuery() {
      const vm = this;
      if (!vm.mutateHistory) {
        vm.search = '';
      } else {
        this.getSearchResults();
      }
    },
    getModelNameForResults() {
      const vm = this;
      if (vm.readableModelName) {
        return `${vm.readableModelName}s`;
      }

      return 'results';
    },
    hideAddForm() {
      this.showingAddForm = false;
      this.$emit('doneAdding');
    },
    showAddClicked() {
      this.showingAddForm = true;
      this.$emit('adding');
    },
    // TODO dry this up
    getAttemptedEventName() {
      const vm = this;
      return vm.search;
    },
    getAttemptedFirstName() {
      const vm = this;
      const inputText = vm.search.split(' ');
      if (inputText.length > 0) {
        return inputText[0];
      }
      return '';
    },
    getAttemptedLastName() {
      const vm = this;
      const inputText = vm.search.split(' ');
      if (inputText.length > 1) {
        return inputText[1];
      }
      return '';
    },
    getPageParams(page, perPage) {
      return `page=${page}&per_page=${perPage}`;
    },
    setNoMoreData() {
      const vm = this;
      vm.nextPageUrl = null;
      if (vm.resultCount === '0') {
        vm.noMoreData = false;
      } else {
        vm.noMoreData = true;
      }
    },
    setupInfiniteScroll(headers) {
      const vm = this;

      vm.resultCount = headers.totalcount;

      if (!headers.link) {
        vm.setNoMoreData();
        return;
      }

      const paginationLinks = headers.link.split(',');
      let nextLinkDetected = false;
      paginationLinks.forEach((l) => {
        const nextRel = 'rel="next"';
        if (l.indexOf(nextRel) !== -1) {
          const regExp = /\<([^>]+)>/;
          vm.nextPageUrl = regExp.exec(l)[1];
          nextLinkDetected = true;
        }
      });

      if (!nextLinkDetected) {
        vm.setNoMoreData();
      }
    },
    getSearchResults() {
      const vm = this;
      vm.resetSearchResults();

      // Ok so I need to reset the page to 1, when a new query is typed
      if (vm.search.length === 0 && vm.displayAllOnEmpty === false) {
        return;
      }

      vm.searching = true;
      let qAppend = vm.baseUrl.includes('?') ? '&' : '?';
      let url = `${vm.baseUrl}${qAppend}query=${this.search}&${this.getPageParams(vm.page, vm.perPage)}`;

      if (vm.profilesOnly) {
        url = `${url}&filter=profiles`;
      } else if (vm.guardiansOnly) {
        url = `${url}&filter=guardians`;
      } else if (vm.billables) {
        url = `${url}&filter=billables`;
      }

      if (vm.includeGuests) {
        url = `${url}&guests=true`
      }

      if (vm.rosterId) {
        url = `${url}&roster_id=${vm.rosterId}`
      }

      if (Array.isArray(vm.rosterIds) && vm.rosterIds.length > 0) {
        let rIds = [];
        vm.rosterIds.forEach((rId) => {
          rIds.push(`roster_ids[]=${rId}`)
        })
        url = `${url}&${rIds.join('&')}`;
      }

      if (vm.queryDeleted) {
        url = `${url}&query_deleted=true`
      }

      // This gets tricky I need to be able to pass in
      // some way for this component to render and parse any arbitrary
      // data element.
      axios.get(url)
        .then((response) => {
          vm.searching = false;

          vm.results = response.data[vm.responseKey];
          vm.setupInfiniteScroll(response.headers);
          vm.resultsLoaded = true;

          const query = vm.search.length > 0 ? vm.search : null;
          vm.historyReplaceState(query);
          vm.emitSearchResultsChanged();
        })
        .catch((error) => {
          vm.resetSearchResults();
          vm.errorMessage = `Error searching for ${vm.responseKey}`;
          vm.error = true;
        });
    },
    // This function is _throttled above, so we have some protection against the native scroll listener
    // Still need to be cognizant that I'm not requesting more than one page though
    getMoreSearchResults() {
      const vm = this;
      if (vm.loadingMore) {
        // In this scenario a load more page request is already happening
        return;
      }

      vm.loadingMore = true;
      vm.page += 1;
      axios.get(vm.nextPageUrl)
        .then((response) => {
          vm.loadingMore = false;
          vm.results = vm.results.concat(response.data[vm.responseKey]);
          vm.setupInfiniteScroll(response.headers);
          vm.error = false;
          vm.emitSearchResultsChanged();
        })
        .catch((error) => {
          vm.resetSearchResults();
          vm.errorMessage = `Error searching for ${vm.responseKey}`;
          vm.error = true;
        });
    },
    emitSearchResultsChanged() {
      const vm = this;
      vm.$notificationManager.$emit('search-results-changed', vm.responseKey, vm.search, vm.results);
    },
    resetSearchResults() {
      const vm = this;
      vm.searching = false;

      vm.loadingMore = false;
      vm.noMoreData = false;

      vm.nextPageUrl = null;
      vm.page = 1;
      vm.perPage = 30;

      vm.resultsLoaded = false;
      vm.resultCount = 0;
      vm.results = [];

      vm.error = false;
      vm.errorMessage = '';

      vm.historyReplaceState(null);
      vm.emitSearchResultsChanged();
    },
    historyReplaceState(value) {
      const vm = this;
      if (!vm.mutateHistory) {
        return;
      }

      vm.replaceHistoryState(vm.queryKey, value);
    },
    getInitialQuery() {
      const vm = this;
      if (vm.mutateHistory) {
        return this.getQueryParameter(this.queryKey);
      }

      return '';
    },
    handleScroll() {
      const vm = this;

      const $element = $(vm.$el);
      const triggerPoint = $element.height() + $element.offset().top - 100;
      const currentPosition = $(window).scrollTop() + $(window).height();
      if (currentPosition > triggerPoint && vm.nextPageUrl !== null) {
        this.getMoreSearchResults();
      }
    },
  },
};
</script>
