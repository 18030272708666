export const taggableMix = {
  methods: {
    rosterTagsWithCount: function (wrestlerProfile) {
      if (!wrestlerProfile) {
        return [];
      }
      let rosters = _.flatMap(wrestlerProfile.rosters);
      let tags = _.flatMap(rosters, "tags");
      let count = _.countBy(tags, "abbreviation");
      return _.map(_.uniqBy(tags, "abbreviation"), function (t) {
        t.count = count[t.abbreviation];
        return t;
      });
    },
    rosterNames: function (wrestlerProfile) {
      if (!wrestlerProfile) {
        return "";
      }
      // This should sort by roster name, then append them in a comma separated string
      return _.map(_.sortBy(_.flatMap(wrestlerProfile.rosters), "name"), "name").join(", ");
    }
  },
};
